import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Flipped } from 'react-flip-toolkit';
import anime from 'animejs';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { TAGS } from '../constants'

const CARD_HEIGHT = 400

const styles = theme => ({
  link: {
    tapHighlightColor: 'transparent',
  },
  projectCard: {
    position: 'relative',
    height: CARD_HEIGHT,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  imageSection: {
    height: CARD_HEIGHT,
    width: '100%',
    overflow: 'hidden',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  imageBackground: {
    width: 'auto',
    height: '100%',
    backgroundColor: theme.palette.common.black,
  },
  image: {
    width: 'auto',
    height: '100%',
    userSelect: 'none',
    userDrag: 'none',
    opacity: 0.8,
    // '&:hover': {
    //   opacity: 0.5,
    // },
  },
  infoTextContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    pointerEvents: 'none',
  },
  infoText: {
    pointerEvents: 'none',
    padding: theme.spacing.unit,
    color: theme.palette.common.white,
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1))',
    height: 125,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    borderBottom: `2px solid ${theme.palette.common.white}`,
    marginBottom: theme.spacing.unit,
  },
  description: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      width: '100%',
    },
  },
  tags: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing.unit,
  },
  dot: {
    margin: `${theme.spacing.unit/2}px 0`,
    height: 12,
    width: 12,
    borderRadius: '50%',
    display: 'inline-block',
    border: `1px solid ${theme.palette.common.white}`,
  },
})

class ProjectCard extends Component {
  shouldFlip = (prev, current) => {
    const { projectId } = this.props
    return current.activeProjectId === projectId || prev.activeProjectId === projectId
  }

  onStart = el => {
    // Ensure card z-index stays above everything else
    el.style.zIndex = 1
  }

  onComplete = el => {
    // Reset card z-index
    el.style.zIndex = null
  }

  removeOpacity = el => {
    el.style.opacity = null
  }

  onAppear = (el, index) => {
    anime({
      targets: el,
      opacity: [0, 1],
      duration: 400,
      delay: 400,
      easing: "easeOutSine"
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { active } = this.props
    const { active: nextActive } = nextProps
    return active !== nextActive
  }

  render() {
    const { classes, projectId, title, description, imgUrl, tags, active } = this.props

    if (active) {
      return <div className={classes.projectCard} />
    }

    return (
      // <Link to={`/${projectId}`} className={classes.link}>
      //   <Flipped
      //     flipId={`${projectId}-card-active`}
      //     shouldFlip={this.shouldFlip}
      //     shouldInvert={this.shouldFlip}
      //     onStart={this.onStart}
      //     onComplete={this.onComplete}
      //   >
          <div
            className={classes.projectCard}
          >
            <Flipped inverseFlipId={`${projectId}-card-active`}>
              <div>
                <Flipped
                  flipId={`${projectId}-card-image-section`}
                  shouldFlip={this.shouldFlip}
                  shouldInvert={this.shouldFlip}
                >
                  <div className={classes.imageSection}>
                    <Flipped inverseFlipId={`${projectId}-card-image-section`}>
                      <div className={classes.imageContainer}>
                        <Flipped
                          flipId={`${projectId}-card-image-background`}
                          shouldFlip={this.shouldFlip}
                          shouldInvert={this.shouldFlip}
                        >
                          <div className={classes.imageBackground}>
                            <Flipped
                              flipId={`${projectId}-card-image`}
                              shouldFlip={this.shouldFlip}
                              shouldInvert={this.shouldFlip}
                              onComplete={this.removeOpacity}
                              opacity
                            >
                              <img
                                src={imgUrl}
                                className={classes.image}
                                alt={title}
                              />
                            </Flipped>
                          </div>
                        </Flipped>
                        <Flipped
                          flipId={`${projectId}-card-info-container`}
                          shouldFlip={this.shouldFlip}
                          shouldInvert={this.shouldFlip}
                          onAppear={this.onAppear}
                        >
                          <div className={classes.infoTextContainer}>
                            <div className={classes.infoText}>
                              <div className={classes.title}>
                                <Typography variant='h5' color='inherit'>{title}</Typography>
                              </div>
                              <div className={classes.description}>
                                <Typography color='inherit'>{description}</Typography>
                                <div className={classes.tags}>
                                  {tags.map(tag =>
                                    <span
                                      key={tag}
                                      className={classes.dot}
                                      style={{backgroundColor: TAGS[tag].color}}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Flipped>
                      </div>
                    </Flipped>
                  </div>
                </Flipped>
              </div>
            </Flipped>
          </div>
      //   </Flipped>
      // </Link>
    )
  }
}

export default withStyles(styles)(ProjectCard);

import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { isProd } from './utils'

import HomePage from './HomePage'

ReactGA.initialize('UA-3251931-8');
class Analytics extends React.Component {
  // Modified from https://github.com/react-ga/react-ga/issues/122#issuecomment-320436578
  constructor(props) {
    super(props)

    // Initial page load - only fired once
    this.sendPageChange(this.getPage(props.location))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // When props change, check if the URL has changed or not
    if (this.getPage(this.props.location) !== this.getPage(prevProps.location)) {
      this.sendPageChange(this.getPage(this.props.location))
    }
  }

  getPage(location) {
    return location.pathname + location.search + location.hash
  }

  sendPageChange(page) {
    if (isProd) {
      ReactGA.pageview(page)
    }
  }

  render() {
    return null
  }
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
})

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet
          titleTemplate='%s - Eugene Fang'
        />
        <BrowserRouter>
          <React.Fragment>
            <Route path='/:projectId?' component={HomePage} />
            <Route path='/' component={Analytics}/>
          </React.Fragment>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;

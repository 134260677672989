import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  chip: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit/2}px`,
  },
  dot: {
    marginRight: theme.spacing.unit,
    height: 12,
    width: 12,
    borderRadius: '50%',
    display: 'inline-block',
  },
})

class FilterChip extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { selected, disabled } = this.props
    const { selected: nextSelected, disabled: nextDisabled } = nextProps
    return selected !== nextSelected || disabled !== nextDisabled
  }

  render() {
    const { classes, theme, label, color, selected, onClick, disabled, ...restProps } = this.props

    return (
      <Chip
        className={classes.chip}
        style={{
          backgroundColor: selected ? color : null,
          color: selected ? theme.palette.getContrastText(color) : (disabled ? theme.palette.text.disabled : null),
          border: `1px solid ${selected ? theme.palette.getContrastText(color) : theme.palette.common.white}`,
        }}
        label={
          <React.Fragment>
            <span
              className={classes.dot}
              style={{
                backgroundColor: disabled ? theme.palette.action.disabledBackground : color,
                border: `1px solid ${selected ? theme.palette.getContrastText(color) : (disabled ? theme.palette.action.disabled : theme.palette.common.black)}`,
              }}
            />
            {label}
          </React.Fragment>}
        onClick={disabled ? null : onClick}
        {...restProps}
      />
    )
  }
}

FilterChip.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export default withStyles(styles, {withTheme: true})(FilterChip)

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Helmet } from 'react-helmet';
import anime from 'animejs';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import FilterChip from './components/FilterChip'
import ProjectCard from './components/ProjectCard'
import ProjectPage from './ProjectPage'

import { TAGS, TAG_ORDER, CARDS } from './constants'

const styles = theme => ({
  layout: {
    maxWidth: 1400,
    margin: '0 auto',
    padding: `0 ${theme.spacing.unit}px 0`,
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing.unit*3}px 0`,
    },
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.unit*3,
  },
  avatar: {
    width: 100,
    height: 100,
    margin: theme.spacing.unit*3,
    boxShadow: theme.shadows[2],
  },
  projects: {
    margin: theme.spacing.unit,
  },
  projectCard: {
    width: '100%',
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  clearButton: {
    margin: `0 ${theme.spacing.unit/2}px`,
  },
})

class HomePage extends Component {
  state = {
    filters: new Set(),
  }

  handleToggle = tag => {
    let { filters } = this.state
    if (filters.has(tag)) {
      filters.delete(tag)
    } else {
      filters.add(tag)
    }
    requestAnimationFrame(() => this.setState({ filters }))
  }

  clearFilters = () => {
    requestAnimationFrame(() => this.setState({ filters: new Set() }))
  }

  onAppear = (el, index) => {
    anime({
      targets: el,
      opacity: [0, 1],
      duration: 400,
      delay: index * 50,
      easing: "easeOutSine"
    })
  }

  onExit = (el, index, removeElement) => {
    anime({
      targets: el,
      opacity: 0,
      duration: 150,
      complete: removeElement,
      delay: index * 20,
      easing: "easeOutSine"
    })
  }

  shouldFlip = (prev, current) => {
    return prev.numFilters !== current.numFilters
  }

  render() {
    const { classes, match, location } = this.props
    const { filters } = this.state
    const { projectId: activeProjectId } = match.params

    const hasFilters = filters.size !== 0
    const remainingTags = new Set()

    let filteredCards = CARDS // Show all cards if there are no filters
    if (hasFilters) {
      filteredCards = CARDS.filter(item => {
        // Show only cards with all selected filters
        const itemTags = new Set(item.tags)
        const difference = new Set([...filters].filter(t => !itemTags.has(t)))
        if (difference.size === 0) {
          [...itemTags].forEach(t => remainingTags.add(t))
          return true
        }
        return false
      })
    }

    return (
    <React.Fragment>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className={classes.layout}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <div className={classes.profile}>
              <Avatar src='/images/eugene.jpg' alt='Eugene Fang' className={classes.avatar} />
              <Typography variant='h2' align='center' gutterBottom>
                Hi, I'm Eugene!
              </Typography>
              <Typography align='center'>
                Website construction in progress...
              </Typography>
              <Typography align='center'>
                In the meantime, you can check out my <a href='https://github.com/fangeugene'>GitHub</a>.
              </Typography>
            </div>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.projects}>
              <Typography variant='h3' gutterBottom>
                Projects
              </Typography>
              {TAG_ORDER.map(tag => {
                const { label, color } = TAGS[tag]
                return (
                    <FilterChip
                      key={tag}
                      label={label}
                      color={color}
                      selected={filters.has(tag)}
                      onClick={this.handleToggle.bind(this, tag)}
                      disabled={hasFilters && !remainingTags.has(tag)}
                    />
                )
              })}
              {hasFilters && <Button
                className={classes.clearButton}
                variant='outlined'
                size='small'
                onClick={this.clearFilters}
              >
                Clear filters
              </Button>}
              <Flipper
                flipKey={`${filters.size}-${activeProjectId}`}
                decisionData={{
                  numFilters: filters.size,
                  activeProjectId: activeProjectId,
                }}
              >
                <Grid container spacing={16}>
                  {filteredCards.map(({ projectId, title, description, imgUrl, tags }) =>
                    <Flipped
                      key={projectId}
                      flipId={`${projectId}-card-filter`}
                      shouldFlip={this.shouldFlip}
                      shouldInvert={this.shouldFlip}
                      onAppear={this.onAppear}
                      onExit={this.onExit}
                    >
                      <Grid item xs={12} md={6} lg={4}>
                        <ProjectCard
                          projectId={projectId}
                          title={title}
                          description={description}
                          imgUrl={imgUrl}
                          tags={tags}
                          active={projectId === activeProjectId}
                        />
                      </Grid>
                    </Flipped>
                  )}
                </Grid>
                <Route key={location.key} path='/:projectId' component={ProjectPage} />
              </Flipper>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
    );
  }
}

export default withStyles(styles)(HomePage);

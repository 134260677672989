import green from '@material-ui/core/colors/green'
import indigo from '@material-ui/core/colors/indigo'
import purple from '@material-ui/core/colors/purple'
import red from '@material-ui/core/colors/red'

export const TAGS = {
  'robots': {
    label: 'Robots',
    color: red[500],
  },
  'tba': {
    label: 'The Blue Alliance',
    color: indigo[500],
  },
  'software': {
    label: 'Software',
    color: green[500],
  },
  'hardware': {
    label: 'Hardware',
    color: purple[500],
  },
}

export const TAG_ORDER = ['robots', 'tba', 'software', 'hardware']

export const CARDS = [
  {
    projectId: 'tba',
    title:'The Blue Alliance',
    description:'Open-source website resource for the FIRST Robotics Competition with over 3,000,000 visits annually',
    imgUrl:'/images/thebluealliance.png',
    tags: ['tba', 'software'],
  },
  {
    projectId: 'tba-ls',
    title:'TBA | LiveScore',
    description:'Automated system for extracting and displaying real-time scores from streaming FIRST Robotics Competition video',
    imgUrl:'/images/tba-livescore.jpg',
    tags: ['tba', 'software'],
  },
  {
    projectId: 'discobot',
    title:'DisCoBot',
    description:'Autonomous mobile robot for radiometric assay of Uranium-235 in gaseous diffusion piping',
    imgUrl:'/images/discobot.jpg',
    tags: ['robots', 'hardware'],
  },
  {
    projectId: 'autokrawlers',
    title:'AutoKrawlers',
    description:'Pair of highly-mobile custom robots for single and multi-agent planetary robotics research',
    imgUrl:'/images/autokrawlers.jpg',
    tags: ['robots', 'software', 'hardware'],
  },
  {
    projectId: 'scot-t',
    title:'SCOT-T',
    description:'Prototype small-scale, lightweight, high-mobility Moon rover',
    imgUrl:'/images/scot-t.jpg',
    tags: ['robots'],
  },
  {
    projectId: 'andy',
    title:'Andy',
    description:'Protoflight Google Lunar XPRIZE rover with four wheels, skid-steer, and passive rocking suspension',
    imgUrl:'/images/andy.jpg',
    tags: ['robots'],
  },
  {
    projectId: 'tba-gd',
    title:'TBA | GameDay',
    description:'Webcast aggregation platform for the FIRST Robotics Competition',
    imgUrl:'/images/tba-gameday.jpg',
    tags: ['tba', 'software'],
  },
  {
    projectId: 'sidewinder',
    title:'Sidewinder',
    description:'Omni-directional drive platform with three independently steered and driven wheel modules',
    imgUrl:'/images/sidewinder.jpg',
    tags: ['robots', 'software', 'hardware'],
  },
  {
    projectId: 'jolt',
    title:'Jolt',
    description:'1/12 scale autonomous racecar that won the 2013 USA Freescale Cup',
    imgUrl:'/images/jolt.jpg',
    tags: ['robots', 'software', 'hardware'],
  },
]

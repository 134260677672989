import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Flipped } from 'react-flip-toolkit';
import { Helmet } from 'react-helmet';
import anime from 'animejs';

import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { CARDS } from './constants'

const styles = theme => ({
  background: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    overflow: 'auto',
    zIndex: theme.zIndex.modal + 1,
  },
  imageSection: {
    height: '30vh',
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '50vh',
    },
    width: '100%',
    overflow: 'hidden',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  imageBackground: {
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.common.black,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  textSection: {
    width: '100%',
    boxShadow: theme.shadows[2],
  },
  titleCard: {
    position: 'sticky',
    top: 0,
    padding: `${theme.spacing.unit}px 0`,
  },
  titleArea: {
    maxWidth: 1400,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    margin: `0 ${theme.spacing.unit}px`,
  },
  textArea: {
    maxWidth: 1400,
    margin: '0 auto',
    padding: `${theme.spacing.unit*2}px ${theme.spacing.unit}px 0`,
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing.unit*2}px ${theme.spacing.unit*3}px 0`,
    },
  },
})

class ProjectPage extends Component {
  state = {
    contentRef: null,
  }

  onAppear = (el, index) => {
    anime({
      targets: el,
      opacity: [0, 1],
      duration: 400,
      delay: 400,
      easing: "easeOutSine"
    })
  }

  setContentRef = ref => {
    this.setState({contentRef: ref})
  }

  render() {
    const { classes, match } = this.props
    const { params: { projectId } = {} } = match
    const { contentRef } = this.state

    // TODO: make more efficient
    let card = null
    CARDS.forEach(c => {
      if (c.projectId === projectId) {
        card = c
      }
    })
    const { title, imgUrl } = card

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Flipped
          flipId={`${projectId}-card-active`}
        >
          <div
            ref={this.setContentRef}
            className={classes.background}
          >
            <Flipped inverseFlipId={`${projectId}-card-active`}>
              <div>
                <Flipped
                  flipId={`${projectId}-card-image-section`}
                >
                  <div className={classes.imageSection}>
                    <Flipped inverseFlipId={`${projectId}-card-image-section`}>
                      <div className={classes.imageContainer}>
                        <Flipped
                          flipId={`${projectId}-card-image-background`}
                        >
                          <ParallaxHeader
                            contentRef={contentRef}
                            className={classes.imageBackground}
                          >
                            <Flipped
                              flipId={`${projectId}-card-image`}
                              onComplete={this.removeOpacity}
                              opacity
                            >
                              <img
                                src={imgUrl}
                                className={classes.image}
                                alt={title}
                              />
                            </Flipped>
                          </ParallaxHeader>
                        </Flipped>
                      </div>
                    </Flipped>
                  </div>
                </Flipped>

                <Flipped
                  flipId={`${projectId}-card-text-section`}
                  onAppear={this.onAppear}
                >
                  <div className={classes.textSection}>
                    <Paper className={classes.titleCard} square>
                      <div className={classes.titleArea}>
                        <IconButton
                          className={classes.backButton}
                          component={Link}
                          to='/'
                          variant='outlined'
                        >
                          <ArrowBackIcon fontSize='small' />
                        </IconButton>
                        <Typography variant='h4'>{title}</Typography>
                      </div>
                    </Paper>
                    <div className={classes.textArea}>
                      <TempContent />
                    </div>
                  </div>
                </Flipped>
              </div>
            </Flipped>
          </div>
        </Flipped>
        <Modal open={true} hideBackdrop>
          <div />
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProjectPage);

class ParallaxHeader extends Component {
  state = {
    imageOffset: 0,
  }
  listenerRegistered = false
  throttle = false

  onScroll = () => {
    if (!this.throttle) {
      this.throttle = true
      requestAnimationFrame(() => {
        this.setState({
          imageOffset: this.props.contentRef.scrollTop * 0.5,
        })
        this.throttle = false
      })
    }
  }

  componentDidUpdate() {
    const { contentRef } = this.props
    if (contentRef && !this.listenerRegistered) {
      this.listenerRegistered = true
      contentRef.addEventListener('scroll', this.onScroll, false)
    }
  }

  componentWillUnmount() {
    const { contentRef } = this.props
    if (contentRef) {
      contentRef.removeEventListener('scroll', this.onScroll, false)
    }
  }

  render() {
    const { className, children, contentRef, ...restProps } = this.props
    const { imageOffset } = this.state
    return (
      <div
        className={className}
        style={{
          transform: `translate3d(0, ${imageOffset}px, 0)`,
        }}
        {...restProps}
      >
        {children}
      </div>
    )
  }
}

const TempContent = () => {
  return (
    <React.Fragment>
      <p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p><p>HIHI</p>
    </React.Fragment>
  )
}
